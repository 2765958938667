import { React, useState, useCallback } from 'react'
import { LegacyCard, BlockStack, Popover, ActionList } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GlobalSvg } from '../assets/GlobeAsiaFilledIcon.svg'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation('languageSwitcher')
  const [active, setActive] = useState(false)

  const toggleActive = useCallback(() => setActive((active) => !active), [])

  const handleLanguageChange = useCallback(
    (lang) => {
      i18n.changeLanguage(lang)
      setActive(false)
    }, [i18n])

  const activator = (
    // <Button onClick={toggleActive} disclosure="select">
    //   選擇語系
    // </Button>
    <GlobalSvg
      onClick={toggleActive}
      style={{ width: '30px', height: '30px', fill: '#000f42' }}/>
  )

  return (
    <LegacyCard.Section>
      <BlockStack gap="5" inlineAlign="end">
        <Popover
          active={active}
          activator={activator}
          // autofocusTarget="first-node"
          onClose={toggleActive}
        >
          <ActionList
            actionRole="menuitem"
            items={[
              {
                content: '繁體中文',
                onAction: () => handleLanguageChange('zh-TW')
              },
              {
                content: 'English',
                onAction: () => handleLanguageChange('en')
              },
              {
                content: '日本語',
                onAction: () => handleLanguageChange('jp')
              }
            ]}
          />
        </Popover>
      </BlockStack>
    </LegacyCard.Section>
  )
}

export default LanguageSwitcher
