import React, { useState, useEffect } from 'react'
import { adminInstance } from '../../helper'
import { Button, Input, Table, message } from 'antd'
import { ArrowTopRightOnSquareIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import Layout from '../../components/Layout/admin/Layout'
import ShopInfoEditModal from '../../components/admin/ShopInfoEditModal'
import UploadToOrcaModal from '../../components/admin/UploadToOrcaModal'

const { Column } = Table

const onChange = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra)
}

const AdminPage = () => {
  const [shops, setShops] = useState([])
  const [selectedShop, setSelectedShop] = useState(null)
  const [openEditModal, setOpenModal] = useState(false)
  const [openUploadToOrcaModal, setOpenUploadToOrcaModal] = useState(false)
  const [filter, setFilter] = useState('')

  const showEditModal = (shopId) => {
    setSelectedShop(shopId)
    setOpenModal(true)
  }

  const closeEditModal = () => {
    setOpenModal(false)
  }

  const impersonate = async (shopname) => {
    try {
      const res = await adminInstance.get(`/shop/token?shop=${shopname}`)
      window.localStorage.setItem('shop', shopname)
      window.localStorage.setItem('accessToken', res.data.access_token)
      window.open('/', '_blank')
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const fetchShops = async () => {
    try {
      const res = await adminInstance.get('/shops?per_page=200')
      setShops(res.data.data)
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const filteredShops = shops.filter(shop => {
    return (
      (shop.shopname.toLowerCase().includes(filter.toLowerCase()) ||
      shop.companyName?.toLowerCase().includes(filter.toLowerCase()))
    )
  })

  const handleOnUploadToOrca = ({ shop, month }) => {
    adminInstance
      .get(`/upload-to-orca?shop=${shop}&month=${month}`)
      .then((res) => message.success(`[${res.status}] ${JSON.stringify(res.data)}`))
      .catch((error) => message.error(`${error.response.data}`))
  }

  useEffect(() => {
    fetchShops()
  }, [openEditModal])

  const subscriptionPlan = {
    trial: '試用版',
    free: '免費版',
    subscribe: '付費版'
  }

  const orcaState = {
    0: '基本資料未設定',
    1: '基本資料已設定',
    2: '自動拋轉資料到 ORCA 進行中',
    3: '自動拋轉資料到 ORCA 完成',
    4: '已送出分析請求但未完成',
    5: '分析完成'
  }

  return (<Layout>
    {selectedShop && <ShopInfoEditModal open={openEditModal} onClose={closeEditModal} shopId={selectedShop} />}
    {openUploadToOrcaModal && <UploadToOrcaModal active={openUploadToOrcaModal} setActive={setOpenUploadToOrcaModal} shopList={shops} handleOnUploadToOrca={handleOnUploadToOrca} />}
    <Button type="primary" onClick={() => setOpenUploadToOrcaModal(true)} style={{ float: 'right', marginBottom: '10px', background: '#1a8733', borderColor: '#1a8733' }}>同步資料至 ORCA</Button>
    <Input style={{ marginBottom: '15px' }} placeholder="搜尋商店名稱 / 公司名稱" value={filter} onChange={(e) => setFilter(e.target.value)} />
    <Table dataSource={filteredShops} onChange={onChange}>
      <Column title="商店名稱" dataIndex="shopname" key="shopname"
        filters={[
          { text: 'shopify', value: 'shopify' },
          { text: 'shopline', value: 'shopline' },
          { text: 'cyberbiz', value: 'cyberbiz' }
        ]}
        onFilter={(value, shop) => shop.shopname.includes(value)} />
      <Column title="公司名稱" dataIndex="companyName" key="companyName" />
      <Column title="版本" key="payMode"
        render={(_, shop) => (<>
          <span>{subscriptionPlan[shop.payMode]}</span>
        </>)}
        filters={[
          { text: '試用版', value: 'trial' },
          { text: '免費版', value: 'free' },
          { text: '付費版', value: 'subscribe' }
        ]}
        onFilter={(value, shop) => shop.payMode === value} />
      <Column title="資料上傳狀態" key="orcaState" render={(_, shop) => (<>
        <span>{orcaState[shop.orcaState]}</span>
      </>)} />
      <Column title="安裝日" dataIndex="installDate" key="installDate" sorter={(a, b) => a.installDate - b.installDate } />
      <Column title="試用期到期日" dataIndex="trialEndDate" key="trialEndDate" sorter={(a, b) => a.trialEndDate - b.trialEndDate } />
      <Column title="操作" key="operation" render={(_, shop) => (<>
        <button
          type="button"
          style={{ marginRight: '5px' }}
          onClick={() => showEditModal(shop.id)}
        >
          <PencilSquareIcon style={{ width: '15px', height: '15px' }} />
        </button>
        <button
          type="button"
          onClick={() => impersonate(shop.shopname)}
        >
          <ArrowTopRightOnSquareIcon style={{ width: '15px', height: '15px' }} />
        </button>
      </>)} />
    </Table>
  </Layout>)
}
export default AdminPage
